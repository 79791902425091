<template>
  <div class="contents-area search-contents">
    <div class="full-content">
      <div class="search-form-content" id="searchForm">
        <div class="container">
          <SearchForm
            @country-selected="refreshCountry"
            @go-searching="refreshSearch"
          />
        </div>
      </div>
      <div class="search-results">
        <div class="container">
          <div class="loading-data" v-if="!companiesLoaded">
            <div class="loader-cont">
              <Loader />
            </div>
          </div>
          <div class="full-data-loaded" v-if="companiesLoaded">
            <h1 v-if="!curCountry.online">
              {{ searchQuery }} {{ translations("InCountry") }}
              <router-link :to="'/country/' + curCountry.link_sef"
                ><img
                  class="small-flag"
                  :src="'//' + serverLink + curCountry.flag"
                  alt=""
                />&nbsp;{{ curCountry.text }}</router-link
              >
            </h1>
            <h1 v-if="curCountry.online && !listCompaniesError">
              {{
                translations("FoundNCompaniesRequestInCountry", [
                  foundTitles[
                    pluralName(
                      listCompaniesCount,
                      $cookies.get("user_language")
                    )
                  ],
                  listCompaniesCount +
                    " " +
                    companyTitles[
                      pluralType(
                        listCompaniesCount,
                        $cookies.get("user_language")
                      )
                    ],
                  "`" + searchQuery + "`",
                ]) + " "
              }}
              <router-link :to="'/country/' + curCountry.link_sef"
                ><img
                  class="small-flag"
                  :src="'//' + serverLink + curCountry.flag"
                  alt=""
                />&nbsp;{{ curCountry.text }}</router-link
              >
            </h1>
            <h1 v-if="curCountry.online && listCompaniesError">
              {{
                translations("ErrorRequestInCountry", [
                  foundTitles[
                    pluralName(
                      listCompaniesCount,
                      $cookies.get("user_language")
                    )
                  ],
                  listCompaniesCount +
                    " " +
                    companyTitles[
                      pluralType(
                        listCompaniesCount,
                        $cookies.get("user_language")
                      )
                    ],
                  "`" + searchQuery + "`",
                ]) + " "
              }}
              <router-link :to="'/country/' + curCountry.link_sef"
                ><img
                  class="small-flag"
                  :src="'//' + serverLink + curCountry.flag"
                  alt=""
                />&nbsp;{{ curCountry.text }}</router-link
              >
            </h1>
            <div
              class="found-companies"
              v-if="listCompaniesCount || listCompaniesError"
            >
              <CompaniesList
                :searchQuery="searchQuery"
                :fetchCallback="fetchCallback"
              />
            </div>
            <div
              class="nothing-found"
              v-if="
                curCountry.online && !listCompaniesCount && !listCompaniesError
              "
            >
              <h2>{{ translations("WhyCompanyNotFound") }}</h2>
              <div class="cause">
                <h3>{{ translations("InvalidRequest") }}</h3>
                <p
                  class="grey"
                  v-html="translations('WhyCompanyNotFoundText')"
                ></p>
                <div class="purposes">
                  <button
                    v-scroll-to="{ el: '#searchForm', offset: -100 }"
                    type="button"
                    class="btn btn-blue btn-big"
                  >
                    {{ translations("CorrectQuery") }}
                  </button>
                </div>
              </div>
              <div class="cause">
                <h3>{{ translations("RestrictionOfFreeSearch") }}</h3>
                <p
                  class="grey"
                  v-html="translations('RestrictionOfFreeSearchText')"
                ></p>
                <div class="purposes" v-if="typePurposesLoaded">
                  <button
                    type="button"
                    class="btn btn-blue btn-big"
                    @click="choosePurpose({ purposeId: item.id })"
                    v-for="item in listTypePurposes"
                    :key="item.id"
                  >
                    {{ item.name }}
                    {{ translations("PriceFromEuro", [item.minPrice]) }}
                  </button>
                </div>
              </div>
              <div class="cause">
                <h3>{{ translations("AnyDoubts") }}</h3>
                <p class="grey">{{ translations("GetFreeConsultNow") }}</p>
                <div class="purposes">
                  <button
                    type="button"
                    @click="contactsPopupOpen()"
                    class="btn btn-blue btn-big"
                  >
                    {{ translations("RequestCallback") }}
                  </button>
                  <button type="button" class="btn btn-blue btn-big">
                    {{ translations("WriteToChat") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VUE_APP_BACK_URL,
  pluralType,
  pluralName,
  strippedContent,
} from "@/config";
import Loader from "@/components/app/Loader";
import SearchForm from "@/components/app/forms/SearchForm";
import CompaniesList from "@/components/egrul/search/CompaniesList";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Search",
  components: { Loader, SearchForm, CompaniesList },
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      pluralType,
      pluralName,
      strippedContent,
      companyTitles: [],
      foundTitles: [],
      searchQuery: "",
      showNothing: false,
    };
  },
  computed: {
    ...mapGetters([
      "translations",
      "curCountry",
      "isCountriesLoaded",
      "typePurposesLoaded",
      "listTypePurposes",
      "curTypePurpose",
      "productsLoaded",
      "listProducts",
      "listCompanies",
      "listCompaniesCount",
      "listCompaniesError",
      "listPurposeProductTypes",
      "companiesLoaded",
      "isContactsPopupOpened",
      "ListLoggedUser",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchTypePurposes",
      "fetchProducts",
      "fetchCompanies",
      "setCompanyFromQuery",
      "setCurTypePurpose",
      "setCurProductType",
      "clearCompanies",
      "contactsPopupOpen",
      "contactsPopupClose",
    ]),
    async fetchCompaniesOnline(page) {
      console.log("Companies page: ", page);
      await this.fetchCompanies({
        lang: this.$cookies.get("user_language"),
        query: this.searchQuery,
        countryId: this.curCountry.id,
        countryCode: this.curCountry.code,
        page,
      });
    },
    async fetchCallback(page) {
      await this.fetchCompaniesOnline(page);
    },
    refreshCountry(val) {
      console.log("country-selected: ", val);
      let fullpath = "/search";
      const curSearch = this.strippedContent(
        decodeURIComponent(this.$route.query.query || "")
      );
      const curCountry = this.strippedContent(
        decodeURIComponent(this.$route.query.country || "")
      );
      if (
        curSearch !== this.searchQuery ||
        curCountry !== this.curCountry.code.toLowerCase()
      ) {
        let searchQuery = this.strippedContent(this.searchQuery || "");
        fullpath += `?country=${this.curCountry.code.toLowerCase()}&query=${encodeURIComponent(
          searchQuery
        )}`;
        this.$router.push(fullpath);
        this.$emit("go-searching", curSearch);
      }
    },
    refreshSearch(event) {
      console.log("go-searching");
      this.searchQuery = decodeURIComponent(event || "");
      this.clearCompanies();
      if (this.isCountriesLoaded) {
        this.fetchData();
      }
    },
    async fetchData() {
      //console.log("curCountry: ", this.curCountry.id);

      if (this.curCountry.online) {
        await this.fetchCompaniesOnline(1);
      }
      if (
        !this.curCountry.online ||
        (this.curCountry.online && this.listCompaniesError)
      ) {
        await this.fetchProducts({
          lang: this.$cookies.get("user_language"),
          curCountry: this.curCountry.id,
        });
        await this.setCompanyFromQuery({
          query: this.searchQuery,
          country: this.curCountry.text,
        });
      }
    },
    async choosePurpose({ purposeId }) {
      await this.setCurTypePurpose(purposeId);
      await this.setCompanyFromQuery({
        query: this.searchQuery,
        country: this.curCountry.text,
      });

      const types = await this.listPurposeProductTypes;
      console.log("types length: ", types.length);

      if (this.ListLoggedUser.boughts === 0) {
        this.$metrika.reachGoal("TypePurposeSelect", {
          typePurpose: this.curTypePurpose.name,
        });
      }

      if (types.length === 1) {
        const curType = types[0];

        await this.setCurProductType(curType.id);
        this.$router.push("/search/select-product");
        return true;
      }

      this.$router.push("/search/select-type");
    },
  },
  watch: {
    curCountry() {
      this.clearCompanies();
      this.fetchData();
    },
  },
  async created() {
    this.companyTitles = this.translations("PluralCompaniesTitles");
    this.companyTitles = JSON.parse(this.companyTitles.trim());
    this.foundTitles = this.translations("PluralFoundCompanyTitles");
    this.foundTitles = JSON.parse(this.foundTitles.trim());
    await this.clearCompanies();
  },
  async mounted() {
    this.refreshSearch(
      this.strippedContent(decodeURIComponent(this.$route.query.query || ""))
    );
  },
};
</script>